<template>
  <v-card class="px-4 pb-4 pt-5" width="400">
    <v-card-title>
      <v-img :src="logoImg" contain alt="DroneShield" />
    </v-card-title>
    <v-layout row class="justify-center mb-1 mt-3 pt-3">
      <div class="text-center font-weight-bold subheading grey--text">
        Planning Tool
      </div>
    </v-layout>
    <v-card-text>
      <v-container text-xs-center>
        <form @submit.prevent="onSubmit">
          <v-layout row>
            <v-flex xs12>
              <v-text-field
                ref="username"
                name="username"
                label="Username"
                id="username"
                v-model="username"
                type="username"
                color="primary"
                required
                @input="onDismissed"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12>
              <v-text-field
                ref="password"
                name="password"
                label="Password"
                id="password"
                v-model="password"
                type="password"
                color="primary"
                required
                @input="onDismissed"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout row v-if="error">
            <v-divider class="mt-5"></v-divider>
            <v-flex xs12>
              <v-alert :value="error" outline color="primary">{{
                error
              }}</v-alert>
            </v-flex>
          </v-layout>
          <v-layout row class="mt-5">
            <v-flex xs12 class="text-xs-center">
              <d-submit
                @onSubmit="onSubmit"
                v-bind="{
                  status,
                  error,
                  okValue: 'log in',
                  doneValue: 'logged'
                }"
              >
                <template v-slot:icon>
                  <v-icon left>lock_open</v-icon>
                </template>
              </d-submit>
            </v-flex>
          </v-layout>
        </form>
      </v-container>
    </v-card-text>
    <template v-if="!offline" >
      <v-divider class="mt-5"></v-divider>
      <v-card-actions>
        <v-btn flat small color="grey" @click="onClickForget"
        >Forgot password?</v-btn
        >
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

const props = {
  error: {
    type: [String, Object],
    default: null
  },
  status: {
    type: String,
    default: ''
  }
}

export default {
  name: 'LoginCard',
  props,
  data: () => ({
    username: '',
    password: ''
  }),
  computed: {
    ...mapState('system', ['environment', 'offline']),
    ...mapGetters('system', ['assetsPath']),
    logoImg() {
      return require(`@/assets/${this.assetsPath}logo.svg`)
    }
  },
  methods: {
    onClickForget() {
      this.onDismissed()
      this.$emit('clickForget', { forgotten: true })
    },
    onSubmit() {
      const { username, password } = this

      const user = username.trim()
      if (!user || !password) {
        this.error = null
        return
      }

      this.$emit('clickLogin', { username: user, password })
    },
    onDismissed() {
      this.$emit('onDismissed')
    }
  },
  watch: {
    status: {
      handler: function(status) {
        if (status === 'error') {
          this.password = null
          this.$nextTick(() => {
            if (!this.username) {
              this.$refs.username.$refs.input.focus()
            } else {
              this.$refs.password.$refs.input.focus()
            }
          })
        }
      }
    },
    immediate: true
  }
}
</script>
